/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import { DeviceType } from 'Type/Device.type';

import { BOTTOM_CONTENT, BOTTOM_CONTENT_DE, MAZOVIA_STORE } from './Footer.config';
import FooterContent from './FooterContent.component';
import FooterNewsletterContent from './FooterNewsletterContent.component';

import './Footer.style';

/**
  * Page footer
  * @class Footer
  * @namespace Satisfly/Component/Footer/Component  */
export class FooterComponent extends PureComponent {
    static propTypes = {
        isVisibleOnMobile: PropTypes.bool,
        device: DeviceType.isRequired,
        isVisible: PropTypes.bool,
        storeCode: PropTypes.string.isRequired,
    };

    static defaultProps = {
        isVisibleOnMobile: false,
        isVisible: true,
    };

    componentDidMount() {
        const { storeCode } = this.props;
        const widgetID = storeCode === MAZOVIA_STORE ? 'ab104d12-b744-48b6-bfd3-c45527e01b74' : '31bf928b-7ee1-4b3e-a6a5-5f361f4a4a26';

        const wrapper = document.getElementById(widgetID);
        const script = document.createElement('script');

        script.setAttribute('src', `https://trustmate.io/api/widget/${widgetID}/script`);
        script.setAttribute('defer', 'defer');

        if (wrapper) {
            wrapper.appendChild(script);
        }
    }

    renderBottomColumnItem(item) {
        const {
            alt, src, width, height,
        } = item;

        return (
            <img
              block="Footer"
              elem="BottomLogo"
              src={ src }
              alt={ alt }
              width={ width }
              height={ height }
              key={ alt }
            />
        );
    }

    renderBottomColumn(column) {
        const { title, items, payments } = column;

        return (
            <div block="Footer" elem="Column">
                <span block="Footer" elem="BottomText">
                    { title }
                </span>
                <div block="Footer" elem="BottomRow" mods={ { payments } }>
                    { items.map(this.renderBottomColumnItem.bind(this)) }
                </div>
            </div>
        );
    }

    renderBottomFooter() {
        const { storeCode } = this.props;
        const content = storeCode === MAZOVIA_STORE ? BOTTOM_CONTENT_DE : BOTTOM_CONTENT;

        return (
            <div block="Footer" elem="Bottom">
                <ContentWrapper
                  isNotSection
                  wrapperMix={ { block: 'Footer', elem: 'Columns' } }
                >
                    { content.map(this.renderBottomColumn.bind(this)) }
                </ContentWrapper>
            </div>
        );
    }

    renderMainFooter() {
        const { device, storeCode } = this.props;
        const widgetID = storeCode === MAZOVIA_STORE ? 'ab104d12-b744-48b6-bfd3-c45527e01b74' : '31bf928b-7ee1-4b3e-a6a5-5f361f4a4a26';

        return (
            <section block="Footer" elem="Main">
                <ContentWrapper
                  isNotSection
                  label="Footer"
                  wrapperMix={ { block: 'Footer', elem: 'Columns' } }
                >
                    <div>
                        <FooterContent key="footer-content" />
                        <div block="Footer" elem="Widget" id={ widgetID } />
                    </div>
                    <div block="Footer" elem="Column">
                        <FooterNewsletterContent key="footer-newsletter-content" />
                        { device.isMobile ? (
                            <getresponse-form form-id="a3c0ac5c-beec-4867-b23c-244181faac43" e="0" />
                        ) : (<getresponse-form form-id="ed5b0eac-00a2-48f8-8bd1-5353246d0c7d" e="0" />) }
                    </div>
                </ContentWrapper>
            </section>
        );
    }

    render() {
        const { isVisibleOnMobile, isVisible, device } = this.props;

        if (!isVisible) {
            return null;
        }

        if (!isVisibleOnMobile && device.isMobile) {
            return null;
        }

        if (isVisibleOnMobile && !device.isMobile) {
            return null;
        }

        return (
            <footer block="Footer" aria-label="Footer">
                { this.renderMainFooter() }
                { this.renderBottomFooter() }
            </footer>
        );
    }
}

export default FooterComponent;
