/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { connect } from 'react-redux';

import Footer from 'Component/Footer';
import {
    SortDirections,
} from 'Route/CategoryPage/CategoryPage.config';
import {
    CategoryPageContainer as SourceCategoryPageContainer,
    mapDispatchToProps,
    mapStateToProps,
} from 'SourceRoute/CategoryPage/CategoryPage.container';
import { scrollToTop } from 'Util/Browser';
import history from 'Util/History';
import { getQueryParam } from 'Util/Url';

import CategoryPage from './CategoryPage.component';

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/create-config-files
export const MAZOVIA_STORE = 'de_de';

export {
    mapDispatchToProps,
    mapStateToProps,
};

/** @namespace Satisfly/Route/CategoryPage/Container */
export class CategoryPageContainer extends SourceCategoryPageContainer {
    config = {
        sortKey: 'bestsellers',
        sortDirection: SortDirections.desc,
    };

    componentDidMount() {
        const {
            categoryIds,
            category: {
                id,
            },
            storeCode,
        } = this.props;

        scrollToTop();

        /**
         * Always make sure the navigation show / hide mode (on scroll)
         * is activated when entering the category page.
         * */
        this.updateNavigationState();

        /**
         * Always update the history, ensure the history contains category
         */
        this.updateHistory();

        /**
         * Make sure to update header state, if the category visited
         * was already loaded.
         */
        if (categoryIds === id) {
            this.updateBreadcrumbs();
            this.updateHeaderState();
            this.updateMeta();
        } else {
            /**
             * Still update header and breadcrumbs, but ignore
             * the category data, as it is outdated
             */
            this.updateHeaderState(true);
            this.updateBreadcrumbs(true);
        }

        const wrapper = document.head;
        const script = document.createElement('script');

        const url = storeCode === MAZOVIA_STORE ? 'https://trustmate.io/widget/api/0b0f6d43-e66c-4c57-8ade-028a7b943060/multihornet' : 'https://trustmate.io/widget/api/bd4f57c7-0d99-45da-a099-8d8c3b49c270/multihornet';
        script.setAttribute('defer', 'defer');
        script.setAttribute('src', url);

        if (wrapper) {
            wrapper.appendChild(script);
        }
    }

    _getPageFromUrl(url) {
        const { location: currentLocation } = history;
        const location = url || currentLocation;

        return +(getQueryParam('page', location) || 1);
    }

    containerProps() {
        const {
            category,
            filters,
            isMobile,
            sortFields,
            toggleOverlayByKey,
            totalPages,
            totalItems,
            isSearchPage,
            displayMode,
            isLoading,
        } = this.props;

        const {
            selectedLayoutType,
            activeLayoutType,
        } = this.state;

        return {
            appliedFiltersCount: this.getAppliedFiltersCount(),
            category,
            defaultPlpType: this.getDefaultPlpType(),
            filter: this.getFilter(),
            filters,
            isContentFiltered: this.isContentFiltered(),
            isCurrentCategoryLoaded: this.isCurrentCategoryLoaded(),
            isMatchingInfoFilter: this.getIsMatchingInfoFilter(),
            isMatchingListFilter: this.getIsMatchingListFilter(),
            isMobile,
            isSearchPage,
            plpTypes: this.getPlpTypes(),
            selectedFilters: this.getSelectedFiltersFromUrl(),
            selectedSort: this.getSelectedSortFromUrl(),
            sortFields,
            toggleOverlayByKey,
            totalPages,
            totalItems,
            currentPage: this._getPageFromUrl(),
            selectedLayoutType,
            activeLayoutType,
            displayMode,
            productListLoaderRef: this.productListLoaderRef,
            isLoading,
            mobileBackdrop: this.mobileBackdrop,
        };
    }

    getIsMatchingInfoFilter() {
        const {
            isInfoLoading,
        } = this.props;

        return !isInfoLoading;
    }

    getSelectedSortFromUrl() {
        const {
            sortFields: {
                options = [],
            },
            categoryDefaultSortBy,
            isSearchPage,
        } = this.props;
        const { location } = history;

        const {
            sortKey: classDefaultSortKey,
            sortDirection: defaultSortDirection,
        } = this.config;

        /**
         * Default SORT DIRECTION is taken from (sequentially):
         * - URL param "sortDirection"
         * - CategoryPage class property "config"
         */
        const sortDirection = (getQueryParam('sortDirection', location))
            || defaultSortDirection;

        /**
         * Default SORT KEY is taken from (sequentially):
         * - URL param "sortKey"
         * - Category default sort key (Magento 2 configuration)
         * - Product Listing Sort By (Magento 2 configuration)
         * - CategoryPage class property "config"
         * (used when global default sort key does not exist for current category)
         */
        const isClassSortKeyAvailable = !!options.find(
            (sortOption) => sortOption.value === classDefaultSortKey,
        );

        const fallbackSortKey = isClassSortKeyAvailable ? classDefaultSortKey : options[0]?.value;
        const defaultSortKey = isSearchPage ? classDefaultSortKey : window.storeConfig?.catalog_default_sort_by || fallbackSortKey;
        const configSortKey = categoryDefaultSortBy || defaultSortKey;
        const sortKey = getQueryParam('sortKey', location) || configSortKey;

        if (isSearchPage && classDefaultSortKey === 'none' && !getQueryParam('sortKey', location)) {
            return {};
        }

        return {
            sortDirection,
            sortKey,
        };
    }

    render() {
        const { isMobile } = this.props;

        return (
            <>
                <CategoryPage
                  { ...this.containerFunctions }
                  { ...this.containerProps() }
                />
                { isMobile && (
                    <Footer isVisibleOnMobile />
                ) }
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryPageContainer);
